<script>
  import BaseSwatch from '@/components/BaseSwatch'
  import ProductSwatchModal from '@/components/products/ProductSwatchModal'
  import CloseBtn from '@/images/icons/close.svg'

  /**
   * This component shows the first few previews of available options in a collection
   * (like fabrics or finishes). Each option can be selected. If there are too many options
   * to display, a modal link appears. The selected option is always shown first.
   *
   * While for ease of coding, we're using buttons in an unordered list, we're using
   * [aria attributes](https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Techniques/Using_the_radio_role)
   * to keep this semantic and accessible as a selection tool. It's essentially one big form field!
   **/

  export default {
    name: 'ProductSwatchOptions',

    components: {
      // eslint-disable-next-line vue/no-unused-components
      ProductSwatchModal,
      BaseSwatch,
      CloseBtn,
    },

    props: {
      /** The type of options passed; either `single` swatches or `collections` of swatches */
      type: {
        type: String,
        required: true,
        validator: (value) => ['single', 'collections'].includes(value),
      },
      /** UI field label for the whole attribute */
      label: {
        type: String,
        required: true,
      },
      /** Whether this choice is required */
      isRequired: {
        type: Boolean,
        default: false,
      },
      /** Array of attribute OPTIONS (not attributes and not swatch collections) */
      options: {
        type: Array,
        required: true,
      },
      /** The ID of the selected option */
      value: {
        type: [String, Number],
        required: true,
      },
      /** Max number of swatches to show outside of modal */
      maxSwatches: {
        type: Number,
        default: 7,
      },
    },

    data() {
      return {
        /** Collections of swatches (e.g. a fabric line). There may be one or more collections **/
        swatchCollections: [],

        /** Every swatch, from every collection, in one array */
        allSwatches: [],

        /** The ID of the selected swatch */
        selectedSwatchId: '',

        /** Modal controls */
        isModalVisible: false,

        selectedLabel: '',

        showLength: 7,

        scrollHeightElement: 0,
      }
    },

    computed: {
      /** Whether the options are single swatches */
      optionsAreSingleSwatches() {
        return this.type === 'single'
      },

      /** Whether the options are collections of swatches */
      optionsAreCollectionsOfSwatches() {
        return this.type === 'collections'
      },

      /** Get a sluglike ID for the aria label */
      labelId() {
        const slugify = require('@sindresorhus/slugify')
        return slugify(this.label) + '_options'
      },

      /** Determine which swatches will be shown outside the modal */
      visibleSwatches() {
        let displayedSwatches = []
        const truncatedSwatches = this.allSwatches.slice(0, this.showLength)

        /* Is the selected swatch in this smaller array? */
        const selectedInSwatches = truncatedSwatches.find(
          (swatch) => swatch.swatchId === this.selectedSwatchId
        )

        /* If the selected swatch is hidden, move it to the front */
        if (!selectedInSwatches && this.selectedSwatch) {
          displayedSwatches = truncatedSwatches.slice(0, -1)
          displayedSwatches.unshift(this.selectedSwatch)
          return displayedSwatches
        } else {
          return truncatedSwatches
        }
      },

      /** How many additional swatches are there? */
      additionalSwatches() {
        return this.allSwatches.length - this.visibleSwatches.length
      },

      /** Set the swatch tab index: don't interact with inline swatches when modal is visible */
      swatchTabIndex() {
        return this.isModalVisible ? -1 : 0
      },

      /** More swatchCollections than options? We need the option label */
      showOptionLabel() {
        if (this.optionsAreSingleSwatches) {
          return false
        } else {
          return this.swatchCollections.length > this.options.length
        }
      },

      /** The first swatch that matches the currently selected option, if any */
      defaultSwatch() {
        if (this.allSwatches.length === 0) {
          return null
        }
        const firstSwatchFromSelectedOption = this.allSwatches.find(
          (swatch) => swatch.optionId === this.value
        )
        if (firstSwatchFromSelectedOption) {
          return firstSwatchFromSelectedOption
        }
        return this.allSwatches[0]
      },

      /** The current selected swatch */
      selectedSwatch() {
        return this.allSwatches.find((swatch) => swatch.swatchId === this.selectedSwatchId)
      },

      /** Formatted display title for the selected swatch */
      selectedSwatchTitle() {
        if (this.optionsAreSingleSwatches) {
          return this.selectedSwatch.label
        } else {
          return (
            this.selectedSwatch.collectionLabel +
            ' in ' +
            this.selectedSwatch.label +
            (this.showOptionLabel ? ' (' + this.selectedSwatch.optionLabel + ')' : '')
          )
        }
      },

      /** Formatted display description for the selected swatch */
      selectedSwatchDescription() {
        if (this.optionsAreSingleSwatches) {
          return this.selectedSwatch.description
        } else {
          return this.selectedSwatch.collectionDescription
        }
      },

      /** The ID of the selected option */
      selectedOptionId() {
        return this.selectedSwatch.optionId
      },

      selectedImage() {
        return this.selectedSwatch.image.urls[0]
      },
      selectedCollectionLabel() {
        return this.selectedSwatch.collectionLabel ?? null
      },
      selectedName() {
        return this.selectedSwatch.label ?? null
      }
    },

    watch: {
      /** This is hacky? */
      // TODO: Rename this
      selectedSwatchId(newSelectedSwatchId) {
        this.selectedLabel = this.selectedSwatchTitle
        this.$emit('input', {
          optionId: this.selectedOptionId,
          swatchId: newSelectedSwatchId,
          swatchTitle: this.selectedSwatchTitle,
          swatchImage: this.selectedImage ?? null,
          swatchLabel: this.selectedCollectionLabel,
          swatchName: this.selectedName,
        })
      },
    },

    mounted() {
      if (window.innerWidth < 767) {
        this.showLength = 5
      }
    },

    created() {
      // this.label - check options type
      /** Just once, set the swatchCollections and allSwatches data */
      this.options.forEach((option) => {
        /* eslint-disable no-unneeded-ternary */
        if (this.optionsAreSingleSwatches) {
          this.allSwatches.push({
            optionId: option.id,
            optionLabel: option.label,
            swatchId: option.id,
            ...option,
          })
          return
        }
        option.swatchCollections.forEach((collection) => {
          const commonProperties = {
            optionId: option.id,
            optionLabel: option.label,
            collectionId: collection.id,
            collectionLabel: collection.label,
            collectionDescription: collection.description,
          }
          this.swatchCollections.push({
            ...commonProperties,
            manufacturer: collection.manufacturer,
            swatches: collection.swatches,
          })
          collection.swatches.forEach((swatch, index) => {
            swatch.swatchId = collection.id + '_' + index
            this.allSwatches.push({
              ...commonProperties,
              ...swatch,
            })
          })
        })
      })

      /** Set the selected swatch to the default swatch if needed */
      if (this.isRequired) {
        this.selectedSwatchId = this.defaultSwatch.swatchId
      }
    },

    methods: {
      changeLengthCount() {
        this.scrollHeightElement = 0
        this.$refs.scrolledHeight.scrollTop = 0
        if (window.innerWidth < 767) {
          this.showLength = 5
        } else {
          this.showLength = this.maxSwatches
        }
      },
      checkScroll() {
        this.scrollHeightElement = this.$refs.scrolledHeight.scrollTop
      },
      showModal() {
        this.showLength = this.allSwatches.length
        // if (
        //   this.optionsAreCollectionsOfSwatches ||
        //   this.selectedSwatchDescription !== null ||
        //   this.additionalSwatches > 0
        // ) {
        //   this.isModalVisible = true
        //   if (this.$refs.modalButton) {
        //     this.$refs.modalButton.blur()
        //   }
        // }
        // this.isModalVisible = true
        // if (this.$refs.modalButton) {
        //   this.$refs.modalButton.blur()
        // }
      },

      focusModal() {
        if (this.isModalVisible) {
          this.$refs.modal.focusCloseButton()
        }
      },

      closeModal() {
        this.isModalVisible = false
      },
    },
  }
</script>

<template>
  <section>
    <div
      v-if="label"
      :id="labelId"
      class="text-l font-bold uppercase block mb-4 cursor-pointer relative tracking-wide pr-8"
    >
      {{ `${options.length} ${label} Available:` }}
      <span v-if="selectedLabel" class="selectedOption"
        ><em class="ml-2">{{ selectedLabel }}</em></span
      >
      <span
        v-if="visibleSwatches.length > maxSwatches"
        @click="changeLengthCount"
        class="block cursor-pointer absolute top-0 right-0"
        ><CloseBtn class="w-5 h-5"
      /></span>
      <!--<span v-if="selectedSwatch" class="inline-block"> : {{ selectedSwatchTitle }}</span>-->
    </div>

    <ul
      role="radiogroup"
      :aria-labelledby="labelId"
      :aria-activedescendant="selectedSwatchId"
      @scroll="checkScroll"
      ref="scrolledHeight"
      class="group-h-1 flex flex-wrap items-center"
      :class="`${additionalSwatches === 0 ? 'scroll--height' : ''}`"
    >
      <BaseSwatch
        v-for="swatch in visibleSwatches"
        :id="swatch.swatchId"
        :key="labelId + '_' + swatch.swatchId"
        v-model="selectedSwatchId"
        :tabindex="swatchTabIndex"
        :image="swatch.image"
        :label="swatch.label"
        :text="swatch.description"
        :type="type"
        :scrollHeight="scrollHeightElement"
        context="inline"
      />

      <li v-if="additionalSwatches > 0" role="radio">
        <button
          ref="modalButton"
          class="inline-block text-sm text-black font-medium text-sm leading-4"
          @click="showModal"
          @focus="focusModal"
        >
          <!-- {{ additionalSwatches }} more -->
          <span>+</span> More options
        </button>
      </li>
    </ul>

    <ProductSwatchModal
      v-if="isModalVisible"
      ref="modal"
      :type="type"
      :selected-swatch-id="selectedSwatchId"
      :attribute-label="label"
      :all-swatches="allSwatches"
      :swatch-collections="swatchCollections"
      :show-option-labels="showOptionLabel"
      :is-required="isRequired"
      @update:selectedSwatchId="selectedSwatchId = $event"
      @close="closeModal"
    />

    <div></div>
  </section>
</template>

<style lang="scss" scoped>
  ul.scroll--height {
    max-height: 470px;
    overflow-x: hidden;
    overflow-y: auto;
    @media (max-width: 767px) {
      max-height: 440px;
    }
  }

  ul.scroll--height::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  ul.scroll--height::-webkit-scrollbar-thumb {
    width: 100%;
    height: 50px;
    background: #6f6f6f;
    border-radius: 100px;
  }

  .selectedOption {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: #000;
    text-transform: initial;

    em {
      font-style: normal;
      color: #6f6f6f;
    }
  }
</style>
