<script>
  import { createNamespacedHelpers } from 'vuex'
  const { mapGetters: mapGlobalGetters } = createNamespacedHelpers('global')

  export default {
    name: 'ProductDownloads',
    props: {
      downloads: {
        type: Array,
        default: () => [],
      },
      otherDownloads: {
        type: Array,
        default: () => [],
      },
      externalLinks: {
        type: Array,
        default: () => [],
      },
    },
    created() {},
    computed: {
      ...mapGlobalGetters(['isTradeAccount']),
    },
  }
</script>
<template>
  <div>
    <dl class="wysiwyg py-6">
      <dd v-if="downloads.length || specExtensions.length || externalLinks.length" class="text-gray-600 leading-snug">
        <div v-if="!isTradeAccount">
          <h3 class="text-black block mb-2">Product Spec Sheets</h3>
          <ul>
            <li v-for="(file, index) in downloads" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.label }} ({{ file.extension.toUpperCase() }})
              </a>
            </li>
          </ul>
          <ul v-if="externalLinks">
            <li v-for="(file, index) in externalLinks" :key="index">
              <a :href="file.url" target="_blank" class="download--btn">
                {{ file.filename }}
              </a>
            </li>
          </ul>
        </div>
        <div v-else>
          <div class="mb-4">
            <h3 class="text-black block mb-2">Product Spec Sheets</h3>
            <ul>
              <li v-for="(file, index) in downloads" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.label }} ({{ file.extension.toUpperCase() }})
                </a>
              </li>
            </ul>
            <ul v-if="externalLinks">
              <li v-for="(file, index) in externalLinks" :key="index">
                <a :href="file.url" target="_blank" class="download--btn">
                  {{ file.filename }}
                </a>
              </li>
            </ul>
          </div>
          <div v-if="otherDownloads.length > 0">
            <div
              v-for="(file, index) in otherDownloads"
              :class="`${index !== otherDownloads.length - 1 ? 'mb-4' : 'mb-0'}`"
              :key="index"
            >
              <h3 v-if="file.label.length > 0" class="text-black block mb-2">{{ file.label }}</h3>
              <ul>
                <li v-for="(fl, idx) in file.files" :key="idx">
                  <a :href="fl.url" target="_blank" class="download--btn">
                    {{ fl.label }} ({{ fl.extension.toUpperCase() }})
                  </a>
                </li>
              </ul>
              <ul v-if="file.links.length > 0">
                <li v-for="(fl, idx) in file.links" :key="idx">
                  <a :href="fl.url" target="_blank" class="download--btn">
                    {{ fl.filename }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </dd>
    </dl>
  </div>
</template>
<style lang="scss">
  .wysiwyg a.download--btn {
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #000000;
    border-color: #000000;
    font-weight: 600;
    letter-spacing: 0.02em;
    border-style: solid;

    &:hover {
      border-color: transparent;
    }
  }
</style>
